<template>
    <div class="container">
        <div class="p-3">
                <div class="row mb-3">
                    <div class="col-md-6 col-12 mb-3">
                        <model-list-select
                        :list="customers"
                        class="form-control"
                        option-value="tc_id"
                        option-text="tc_name"
                        v-model="rec.sl_customer"
                        placeholder="Pilih Customer"
                        v-on:input="setZone()"
                ></model-list-select>

                    </div>
                    <div class="col-md-2 col-12">
                                <button class="btn btn-success col-12 col-md-8" @click="openMap()">Open map &nbsp;<i class="fa fa-arrow-right"></i></button>
                    </div>
                </div>
                
            <div id="map">
                <l-map class="w3-round-large" style="height: 52vh; z-index:0" :zoom="map.currentZoom" :center="map.center">
                    <l-tile-layer :url="map.url" :attribution="map.attribution"></l-tile-layer>
                    <l-marker :lat-lng="map.markerLatLng">
                        <l-tooltip :options="{
                                permanent: true,
                                interactive: true 
                        }">
                            {{map.markerName}}
                        </l-tooltip>
                    </l-marker>
                </l-map>
            </div>
        </div>
    </div>
</template>
<script>
/* eslint-disable */
// import { latLng } from "leaflet";
import {
  LMap,
  LTileLayer,
  LMarker,
  LControl,
  LTooltip
} from "vue2-leaflet";
import { Icon } from "leaflet";
import axios from "axios";
import 'leaflet/dist/leaflet.css';
import { ModelListSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
export default {
    // head: {
    //     title: {
    //       inner: 'Main'
    //     }
    // },
    name : 'Location',
    components: {
        LMap,
        LTileLayer,
        LMarker,
        LControl,
        LTooltip,
        ModelListSelect
    },
    data() {
        return {
            screenHeight : 0,
            map: {
                zoom: 8,
                center: [
                    -7.240674, 112.693901
                ],
                url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
                currentZoom: 15,
                markerLatLng: [
                    -7.240674, 112.693901
                ],
                attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                markerName: ""
            },
            rec: {},
            customers: [],
        }
    },
    methods : {
        findObj(obj, key, value) {
            let ketemu = 0;
            for (var pkey in obj) {
                if (obj[pkey][key] == value) {
                    break;
                } else {
                    ketemu++;
                }
            }
            if(obj[ketemu]!=undefined){
                return obj[ketemu];
            }else
                return null;
        },
        setZone(){
            let self = this;
            let objCustomer = self.findObj(self.customers,'tc_id',self.rec.sl_customer);

            self.map.center = [
                objCustomer.tc_lat,
                objCustomer.tc_lng
            ];
            self.map.markerLatLng = [
                objCustomer.tc_lat,
                objCustomer.tc_lng
            ];
            self.map.markerName = objCustomer.tc_name;
        },
        openMap(){
            let self = this;
            if (self.rec.sl_customer == undefined) {
                this.$toast.warning('Must chose customer.!');
            }else{
                let latLng = self.map.center[0]+","+self.map.center[1];
                window.open("geo:?q="+latLng+"&z=15");
            }
        },
        setInit() {
            let self = this;
            axios.post("ApiCustomer.php",{
                pfunction : 'setcustomer'
            }).then(function (response) {
                self.customers = response.data.rec;
            });
        }
    },
    mounted(){
        this.screenHeight = window.innerHeight;
        this.setInit();
    }
};
</script>
